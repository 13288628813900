<template>
   <div class="fundusTest">
     <h3><PopularScience :text="'眼底检查'" :gjz="'jianyanjing'"></PopularScience></h3>
     <div class="top">
        <p style="margin-right: 0.4rem">眼底镜是否窥视不清</p>
        <el-radio-group v-model="postData.S_OP_EC_Ophthalmoscope">
          <el-radio :label="'1'">是</el-radio>
          <el-radio :label="'0'">否</el-radio>
        </el-radio-group>
      </div>
     <div v-if="postData.S_OP_EC_Ophthalmoscope === '0'">
       <ul class="tx">
         <li></li>
         <li><PopularScience :text="'视乳头'" :gjz="'shirutou'"></PopularScience></li>
         <li><PopularScience :text="'黄斑'" :gjz="'huangban'"></PopularScience></li>
         <li><PopularScience :text="'视网膜'" :gjz="'shiwangmo'"></PopularScience></li>
         <li><PopularScience :text="'视网膜血管'" :gjz="'shiwangmoxueguan'"></PopularScience></li>
         <li><PopularScience :text="'注视性质'" :gjz="'zhushixingzhi'"></PopularScience></li>
       </ul>
       <ul class="tx" style="padding-top: 0;">
         <li>OD</li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_OpticPapillaOD"
               @change="bh($event, 'S_OP_EC_OpticPapillaOD', srt[0].value, 'srtOD')"
               @focus="xzIdx = 1"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in srt"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_YellowSpotOD"
               @change="bh($event, 'S_OP_EC_YellowSpotOD', hb[0].value, 'hbOD')"
               @focus="xzIdx = 2"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in hb"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_RetinaOD"
               @change="bh($event, 'S_OP_EC_RetinaOD', swm[0].value, 'swmOD')"
               @focus="xzIdx = 3"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in swm"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_RetinaVeinOD"
               @change="bh($event, 'S_OP_EC_RetinaVeinOD', swmxg[0].value, 'swmxgOD')"
               @focus="xzIdx = 4"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in swmxg"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_GazePropertiesOD"
               @change="bh($event, 'S_OP_EC_GazePropertiesOD', zsxz[0].value, 'zsxzOD')"
               @focus="xzIdx = 5"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in zsxz"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
       </ul>
       <ul class="tx">
         <li>OS</li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_OpticPapillaOS"
               @change="bh($event, 'S_OP_EC_OpticPapillaOS', srt[0].value, 'srtOS')"
               @focus="xzIdx = 6"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in srt"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_YellowSpotOS"
               @change="bh($event, 'S_OP_EC_YellowSpotOS', hb[0].value, 'hbOS')"
               @focus="xzIdx = 7"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in hb"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_RetinaOS"
               @change="bh($event, 'S_OP_EC_RetinaOS', swm[0].value, 'swmOS')"
               @focus="xzIdx = 8"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in swm"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_RetinaVeinOS"
               @change="bh($event, 'S_OP_EC_RetinaVeinOS', swmxg[0].value, 'swmxgOS')"
               @focus="xzIdx = 9"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in swmxg"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
         <li>
           <el-select
               class="srr"
               v-model="postData.S_OP_EC_GazePropertiesOS"
               @change="bh($event, 'S_OP_EC_GazePropertiesOS', zsxz[0].value, 'zsxzOS')"
               @focus="xzIdx = 10"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in zsxz"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </li>
       </ul>
     </div>


     <div v-if="0" class="ygfs">
       <p>验光方式</p>
       <el-radio-group v-model="postData.S_OP_EC_CheckType" class="ygfs-xx">
         <el-radio :label="'0'">小瞳验光</el-radio>
         <el-radio :label="'1'">散瞳</el-radio>
         <el-radio :label="'2'">左散</el-radio>
         <el-radio :label="'3'">右散</el-radio>
       </el-radio-group>
       <p>散瞳用药</p>
       <div class="yy" v-if="postData.S_OP_EC_CheckType != '0'">
         <el-select
             class="srr"
             v-model="stv"
             @change="bh"
             @focus="xzIdx = 11"
             filterable
             allow-create
             multiple
             collapse-tags
             default-first-option
             placeholder="请选择或输入">
           <el-option
               v-for="item in st"
               :key="item.value"
               :label="item.label"
               :value="item.value">
           </el-option>
         </el-select>
       </div>
     </div>
     <div class="bottom">
       <p>备注</p>
       <el-input
           class="bzs"
           type="textarea"
           placeholder="请输入内容"
           v-model="postData.S_OP_EC_Remark"
           :autosize="{ minRows: 4, maxRows: 4 }"
           resize="none"
           show-word-limit
       >
       </el-input>
     </div>
   </div>
</template>

<script>
// import store from "@/store";

export default {
  name: "fundusTest",
  data() {
    return {
      ydjc: 0,
      ygfs: 0,
      wyOD: '',
      bz: '',
      xzIdx: -1,
      options: [
        {name: '无明显受限',value:0, isshow: false},
        {name: '眼球震颤',value:1, isshow: false},
        {name: '外屏障碍',value:2, isshow: false},
        {name: '内收障碍',value:3, isshow: false},
        {name: '上转障碍',value:4, isshow: false},
        {name: '下转障碍',value:5, isshow: false},
        {name: '手动填写',value:6, isshow: false}
      ],
      postData: {
        S_OP_EC_OpticPapillaOD: ['红色界清，C/D约0.3'],
        S_OP_EC_YellowSpotOD: ['黄斑中心凹反光存'],
        S_OP_EC_RetinaOD: ['色正'],
        S_OP_EC_RetinaVeinOD: ['A:V约2:3'],
        S_OP_EC_GazePropertiesOD: ['中心注视'],

        S_OP_EC_OpticPapillaOS: ['红色界清，C/D约0.3'],
        S_OP_EC_YellowSpotOS: ['黄斑中心凹反光存'],
        S_OP_EC_RetinaOS: ['色正'],
        S_OP_EC_RetinaVeinOS: ['A:V约2:3'],
        S_OP_EC_GazePropertiesOS: ['中心注视'],
        S_OP_EC_Remark: '',
        S_OP_EC_Ophthalmoscope: '0'
      },

      srt: [
        {
          value: '红色界清，C/D约0.3',
          label: '红色界清，C/D约0.3'
        },
        {
          value: '视乳头杯盘比大',
          label: '视乳头杯盘比大'
        },
        {
          value: '视乳头色淡',
          label: '视乳头色淡'
        },
        {
          value: '视乳头水肿',
          label: '视乳头水肿'
        },
        {
          value: '视乳头旁萎缩弧',
          label: '视乳头旁萎缩弧'
        },
        {
          value: '盘沿丢失',
          label: '盘沿丢失'
        },
        {
          value: '盘沿出血',
          label: '盘沿出血'
        },
        {
          value: '视乳头牵牛花改变',
          label: '视乳头牵牛花改变'
        },
        {
          value: '有髓神经改变',
          label: '有髓神经改变'
        }
      ],
      srtOD: ['红色界清，C/D约0.3'],
      srtOS: ['红色界清，C/D约0.3'],

      hb: [
        {
          value: '黄斑中心凹反光存',
          label: '黄斑中心凹反光存'
        },
        {
          value: '黄斑光反射消失',
          label: '黄斑光反射消失'
        },
        {
          value: '黄斑水肿',
          label: '黄斑水肿'
        },
        {
          value: '黄斑出血',
          label: '黄斑出血'
        },
        {
          value: '黄斑裂孔',
          label: '黄斑裂孔'
        },
        {
          value: '黄斑玻璃膜疣',
          label: '黄斑玻璃膜疣'
        },
        {
          value: '黄斑区萎缩',
          label: '黄斑区萎缩'
        },
        {
          value: '前膜',
          label: '前膜'
        }
      ],
      hbOD: ['黄斑中心凹反光存'],
      hbOS: ['黄斑中心凹反光存'],

      swm: [
        {
          value: '色正',
          label: '色正'
        },
        {
          value: '颜色异常',
          label: '颜色异常'
        },
        {
          value: '豹纹状眼底',
          label: '豹纹状眼底'
        },
        {
          value: '视网膜萎缩灶',
          label: '视网膜萎缩灶'
        },
        {
          value: '高度近视视网膜病变',
          label: '高度近视视网膜病变'
        },
        {
          value: '眼底激光斑',
          label: '眼底激光斑'
        },
        {
          value: '棉绒斑',
          label: '棉绒斑'
        },
        {
          value: '硬性渗出',
          label: '硬性渗出'
        },
        {
          value: '视网膜脱落',
          label: '视网膜脱落'
        },
        {
          value: '水肿',
          label: '水肿'
        },
        {
          value: '前膜',
          label: '前膜'
        },
        {
          value: '色素沉着',
          label: '色素沉着'
        }
      ],
      swmOD: ['色正'],
      swmOS: ['色正'],

      swmxg: [
        {
          value: 'A:V约2:3',
          label: 'A:V约2:3'
        },
        {
          value: '静动脉交叉压迫',
          label: '静动脉交叉压迫'
        },
        {
          value: '微动脉瘤',
          label: '微动脉瘤'
        },
        {
          value: '视网膜出血',
          label: '视网膜出血'
        },
        {
          value: '视网膜动脉变细,反光增强',
          label: '视网膜动脉变细,反光增强'
        },
        {
          value: '视网膜静脉迂曲扩张',
          label: '视网膜静脉迂曲扩张'
        },
        {
          value: '视网膜血管闭塞，白鞘',
          label: '视网膜血管闭塞，白鞘'
        }
      ],
      swmxgOD: ['A:V约2:3'],
      swmxgOS: ['A:V约2:3'],

      zsxz: [
        {
          value: '中心注视',
          label: '中心注视'
        },
        {
          value: '旁中心注视',
          label: '旁中心注视'
        },
        {
          value: '周边注视',
          label: '周边注视'
        },
        {
          value: '黄斑区注视',
          label: '黄斑区注视'
        }
      ],
      zsxzOD: ['中心注视'],
      zsxzOS: ['中心注视'],

      st: [
        {
          value: '0.5%复方托吡卡胺(美多丽P)',
          label: '0.5%复方托吡卡胺(美多丽P)'
        },
        {
          value: '1%环戊通',
          label: '1%环戊通'
        },
        {
          value: '1%阿托品膏',
          label: '1%阿托品膏'
        },
        {
          value: '阿托品已散瞳',
          label: '阿托品已散瞳'
        }
      ],
      stv: []
    }
  },
  watch: {
    '$store.state.eyeTest.lxdata': {
      deep: true,
      handler(n, o) {
        for (let key in this.postData) {
          if (key === 'S_OP_EC_Ophthalmoscope') {
            this.postData.S_OP_EC_Ophthalmoscope =  n.S_OP_Json.S_OP_EC_Ophthalmoscope?n.S_OP_Json.S_OP_EC_Ophthalmoscope:'0'
          }else if (key === 'S_OP_EC_Remark'){
            this.postData[key] = n.S_OP_Json[key]
          } else {
            this.postData[key] = n.S_OP_Json[key]?n.S_OP_Json[key].split(','):this.postData[key]
          }
        }
      }
    }
  },
  created() {
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
  },
  methods: {
    bh(e, key, opval, arr) {
      if (e.length > 1) {
        if (e[e.length -1] == opval) {
          this.postData[key] = [opval]
        }else {
          if (e[0] == opval) {
            this.postData[key].shift()
          }
        }
      }

    },
    save() {
      this._api.eyeTest.saveLxd(JSON.parse(JSON.stringify(this.postData)), '眼底检查')
    }
  }
}
</script>

<style scoped lang="scss">
  .fundusTest {
    width: 90%;
    margin: 0 auto;
  }
  .top {
    display: flex;
    justify-content: start;
    align-items: center;
    p:nth-child(1) {
      font-size: 17px;
      border-bottom: 1px solid #000000;
    }
    p:nth-child(2) {padding: 0 60px}
  }
  .tx {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 40px;
    li {
      width: 12vw;
      margin-right: 20px;
    }
    li:nth-child(1) {width: 3vw; text-align: left}
  }
  .ygfs {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 30px;
    .ygfs-xx {
      margin: 0 40px ;
      padding-top: 3px;
    }
    .yy {
      width: 12vw;
      margin-left: 40px;
    }
  }
  .bottom {
    margin-top: 40px;
    display: flex;
    justify-content: start;
    .bzs {
      width: 70%;
      margin-left: 40px;
    }
  }
  h3 {padding: 0.2rem 0}
  ::v-deep .tx .el-select__tags-text {
    display: inline-block;
    max-width: 6em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }
  ::v-deep .tx .el-tag {
    display: flex;
    align-items: center;
  }
</style>
